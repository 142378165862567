import { useEffect, useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { CurrencyInput } from '../../../../../shared/form-controls/form-input/currency-input';
import FormInput from '../../../../../shared/form-controls/form-input';
import FormSelect from '../../../../../shared/form-controls/form-select/form-select';
import { formatMoney, currencyToNumber } from '../../../../../utils/number-formatters';
import styles from '../style.module.scss';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical } from '@fortawesome/free-solid-svg-icons';
import { EditSettingModal } from '../../../../../pages/settings/line-items-page/edit-setting-modal';
import _ from 'lodash';

type ScopeEstimateProps = {
  options: Array<any>;
  allOptions: Array<any>;
  id: string;
  itemIndex: number;
  isLocked: boolean;
  showProfitAsPers: boolean;
  onAddFirstLevelItem: (index: number) => void;
  onRemoveFirstLevelItem: (index: number) => void;
  isTotalLocked?: boolean;
  lineItemOptions?: Array<any>;
  detailedMode?: boolean;
  visibleColumns?: {
    serviceDate: boolean;
    description: boolean;
    qty: boolean;
    unitCost: boolean;
    cost: boolean;
    markup: boolean;
    unitPrice: boolean;
    total: boolean;
    taxable: boolean;
    billable: boolean;
  };
  textAreaEnabled?: boolean;
  onDragEndNested: (result: any) => void;
  dragHandleProps: any;
  onAddNewLineItem: (lineItem: any) => void;
};

export const ScopeEstimateModule = ({
  options,
  allOptions,
  id,
  itemIndex,
  isLocked,
  showProfitAsPers,
  onAddFirstLevelItem,
  onRemoveFirstLevelItem,
  isTotalLocked = false,
  detailedMode = false,
  lineItemOptions = [],
  visibleColumns = {
    serviceDate: false,
    description: true,
    qty: true,
    unitCost: true,
    cost: true,
    markup: true,
    unitPrice: true,
    total: true,
    taxable: true,
    billable: false,
  },
  textAreaEnabled = true,
  onDragEndNested,
  dragHandleProps,
  onAddNewLineItem,
}: ScopeEstimateProps) => {
  const fieldName = 'estData';
  const { watch, setValue, control } = useFormContext();
  const estData = watch(fieldName);
  const curLineItemId = estData[itemIndex]?.lineItemId;
  //const index = estData.findIndex(({ lineItemId }) => lineItemId ===id)>-1?estData.findIndex(({ lineItemId }) => lineItemId ===id):estData.findIndex(({ scopeId }) => scopeId === id);
  const scopeEst = estData[itemIndex];
  const fieldPrefix = `${fieldName}.${itemIndex}`;
  const setValueOptions = { shouldValidate: true };

  const [showEditModal, setShowEditModal] = useState(false);
  const [newLineItemName, setNewLineItemName] = useState('');
  const [lastNewLineItemName, setLastNewLineItemName] = useState('');

  //const [scopeEstimateMode, setScopeEstimateMode] = useState<string>('simple');

  useEffect(() => {
    const { subTotalProfit, subTotalCost, subTotalCtr, qty, unitProfit } = scopeEst;
    const subTotalProfitNumber = currencyToNumber(subTotalProfit);
    const subTotalCostNumber = currencyToNumber(subTotalCost);
    const profitPers =
      subTotalCostNumber === 0 ? 0 : (subTotalProfitNumber / subTotalCostNumber) * 100;

    if (!unitProfit || !qty || qty?.length == 0 || unitProfit?.length == 0) {
      // assume that this is a legacy scope and needs to be updated
      setValue(`${fieldPrefix}.qty`, '1', setValueOptions);
      setValue(`${fieldPrefix}.unitCost`, formatMoneyLocal(subTotalCostNumber), setValueOptions);
      setValue(`${fieldPrefix}.unitPrice`, formatMoneyLocal(subTotalCtr), setValueOptions);
      setValue(`${fieldPrefix}.unitProfit`, formatMoneyLocal(subTotalProfit), setValueOptions);
      setValue(`${fieldPrefix}.subTotalCtr`, formatMoneyLocal(subTotalCtr), setValueOptions);
      setValue(`${fieldPrefix}.subTotalCost`, formatMoneyLocal(subTotalCost), setValueOptions);
      setValue(
        `${fieldPrefix}.subTotalProfit`,
        formatMoneyLocal(subTotalProfitNumber),
        setValueOptions
      );
      setValue(`${fieldPrefix}.serviceDate`, null, setValueOptions);
      setValue(`${fieldPrefix}.taxable`, false, setValueOptions);
      setValue(`${fieldPrefix}.billable`, true, setValueOptions);
    }

    setValue(`${fieldPrefix}.subTotalProfitPers`, profitPers.toFixed(1), setValueOptions);
  }, []);

  const formatMoneyLocal = (value: any) => {
    return formatMoney(value, -1, '');
  };

  const recalculateTotals = (itemIndex?: number) => {
    let estTotalCost = 0;
    let estTotalProfit = 0;
    let estTotalContract = 0;

    estData.forEach((scopeEst: any, index: number) => {
      const { items=[], qty, lineItemId } = scopeEst;

      const isAllowedLineItems = lineItemId?allLineItemSelectOptions.find((option) => option.value === lineItemId)?.allowLineItems:false;

      if ((!items || items.length === 0) && !isAllowedLineItems) {
        if (!qty) {
          return;
        }

        const { subTotalCost, subTotalProfit, subTotalCtr } = scopeEst;
        const subTotalCostNumber = currencyToNumber(subTotalCost);
        const subTotalProfitNumber = currencyToNumber(subTotalProfit);
        const subTotalCtrNumber = currencyToNumber(subTotalCtr);

        estTotalCost += subTotalCostNumber;
        estTotalProfit += subTotalProfitNumber;
        estTotalContract += subTotalCtrNumber;

        return;
      }

      const curQty = currencyToNumber(qty);
      const unitCost =
        items.reduce((acc: number, curr: any) => acc + currencyToNumber(curr.cost), 0) / curQty;
      const subTotalCost = unitCost * curQty;

      const unitPrice =
        items.reduce((acc: number, curr: any) => acc + currencyToNumber(curr.total), 0) / curQty;
      const subTotalCtr = unitPrice * curQty;

      const unitProfit = unitCost === 0 ? 0 : unitPrice - unitCost;
      const subTotalProfit = subTotalCtr - subTotalCost;
      const unitProfitPers = unitCost === 0 ? 0 : (unitProfit / unitCost) * 100;

      if (!qty) {
        return;
      }

      estTotalCost += subTotalCost;
      estTotalProfit += subTotalProfit;
      estTotalContract += subTotalCtr;

      if (itemIndex !== undefined && itemIndex !== index) {
        return;
      }

      const valsToSet = [
        { key: 'unitCost', value: unitCost },
        { key: 'unitPrice', value: unitPrice },
        { key: 'unitProfit', value: unitProfit },
        { key: 'subTotalProfitPers', value: unitProfitPers },
        { key: 'subTotalCost', value: subTotalCost },
        { key: 'subTotalProfit', value: subTotalProfit },
        { key: 'subTotalCtr', value: subTotalCtr },
      ];

      valsToSet.forEach((val) => {
        let newVal = formatMoneyLocal(val.value);
        if (val.key === 'subTotalProfitPers') {
          newVal = val.value.toFixed(1);
        }

        // check to see if value is same as current value
        if (scopeEst[val.key] !== newVal) {
          setValue(`${fieldName}.${index}.${val.key}`, newVal, setValueOptions);
        }
      });
    });

    setValue('estTotalCost', estTotalCost);
    setValue('estTotalProfit', estTotalProfit);
    setValue('estTotalContract', estTotalContract);
  };

  useEffect(() => {
    recalculateTotals(itemIndex);
    //setValue(`${fieldPrefix}.lineItemId`, curLineItemId, setValueOptions);
    const lineItem = lineItemOptions.find((option) => option._id === curLineItemId);
    setLineItemLabel(lineItem?.name??'');
  }, [curLineItemId]);

  const formInputClass = `px-1 ${styles.noLabel} ${detailedMode ? styles.inputWidthDetailed : styles.inputWidth}`;
  const formInputClassShort = `px-1 ${styles.noLabel}  ${detailedMode ? styles.inputWidthDetailedShort : styles.inputWidth}`;
  const formInputClassExtraShort = `px-1 ms-1 mt-1 me-1 ${styles.noLabel}  ${detailedMode ? styles.inputWidthDetailedExtraShort : styles.inputWidth}`;
  const formInputClassLong = `px-1 ${styles.noLabel} ${detailedMode ? styles.inputWidthDetailedLong : styles.inputWidth}`;

  const itemLabelStyle = `${detailedMode ? 'col-md-2' : 'col-md-4'}`;

  const isScopeDisabled = !scopeEst.allowChangeScope;
  const isOptionDisabled = useCallback(
    (option: any) => estData.find((estData: any) => estData.scopeId === option.value),
    [estData]
  );

  const allLineItemSelectOptions = lineItemOptions.map((option) => ({
    value: option._id,
    label: option.name,
    definition: option.definition,
    category: option.qbCategory,
    type: option.type,
    scopeId: option?.jobScopeId,
    itemGroupDetails: option.type === 'Group' ? option.itemGroupDetails : null,
    allowLineItems: option.type === 'Group',
  }));

  const lineItemSelectOptions = allLineItemSelectOptions.filter(
    (option) => option.type !== 'Group'
  );

  // const showQty = true || scopeEstimateMode !== 'simple';
  //const lockQty = scopeEstimateMode === 'simple';

  const updateEstDataSubFields = useCallback(
    (items) => {
      const getValues = (field: string) => watch(field);

      const estDataLocal = _.cloneDeep(items ?? getValues(`${fieldPrefix}.items`));

          estDataLocal.forEach((subItem, subIndex) => {
            setValue(`${fieldPrefix}.items.${subIndex}.lineItemId`, subItem.lineItemId);
            setValue(`${fieldPrefix}.items.${subIndex}.qbItemId`, subItem.qbItemId);
            setValue(`${fieldPrefix}.items.${subIndex}.scopeId`, subItem.scopeId);
            setValue(`${fieldPrefix}.items.${subIndex}.qty`, subItem.qty ?? 0);
            setValue(`${fieldPrefix}.items.${subIndex}.unitCost`, subItem.unitCost ?? 0);
            setValue(`${fieldPrefix}.items.${subIndex}.unitPrice`, subItem.unitPrice ?? 0);
            setValue(`${fieldPrefix}.items.${subIndex}.cost`, subItem.cost);
            setValue(`${fieldPrefix}.items.${subIndex}.profit`, subItem.profit);
            setValue(`${fieldPrefix}.items.${subIndex}.total`, subItem.total);
            setValue(`${fieldPrefix}.items.${subIndex}.profitPers`, subItem.profitPers);
            setValue(`${fieldPrefix}.items.${subIndex}.description`, subItem.description ?? '');
            setValue(`${fieldPrefix}.items.${subIndex}.serviceDate`, subItem.serviceDate);
            setValue(`${fieldPrefix}.items.${subIndex}.taxable`, subItem.taxable ?? false);
            setValue(`${fieldPrefix}.items.${subIndex}.billable`, subItem.billable ?? true);
          });
    },
    [setValue]
  );

  const onRemoveLineItem = (fieldPrefix: string, lineItemIndex: number) => {

    const localEstItems = _.cloneDeep(scopeEst.items).filter((item, index) => index !== lineItemIndex);
    setValue(
      `${fieldPrefix}.items`,
      localEstItems,
      setValueOptions
    );

    updateEstDataSubFields(localEstItems);

    recalculateTotals(itemIndex);

  };

  const renderLineItemLabel = (option, menuIsOpen) => {
    // return html with line item name, description, and category

    if (menuIsOpen) {
      return (
        <div>
          <div className='row d-flex flex-nowrap'>
            <div className='col'>{option.label}</div>
            <div className='col text-muted small'>{option.definition}</div>
            {/*<div className='col ms-auto text-end text-sm small'>Category:<div className='text-muted'>{option.category}</div></div>*/}
          </div>
        </div>
      );
    } else {
      return option.label;
    }
  };
  const renderLineItem = (lineItem: any, index: number) => {
    return (
      <Draggable
        key={`${fieldPrefix}.items.${index}.` + lineItem.lineItemId}
        draggableId={'level2:' + itemIndex + ':' + index + ':' + lineItem.lineItemId}
        index={index}
        isDragDisabled={isLocked}
      >
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            className='row flex-nowrap col-md-12'
            data-testId='bidManagementModalScope'
          >
            <div {...provided.dragHandleProps} className={`px-1 ${styles.dragHandle} ms-2`}>
              <FontAwesomeIcon icon={faGripVertical} />
            </div>

            <div
              className={`px-1 pe-0 ${itemLabelStyle} ${isLocked ? styles.disabledSelect : ''}`}
              style={
                detailedMode ? { width: 'calc((100%/6) - 0.5em' } : { width: 'calc(100%/12*4)' }
              }
            >
              <FormSelect
                label=''
                control={control}
                // add bullet text decoration before
                className={`ms-0 text-start px-1 ${styles.lineItemSelect}`}
                options={[
                  ...lineItemSelectOptions,
                  {
                    value: 'add_new',
                    label: `Add "${newLineItemName}"`,
                    isDisabled: !newLineItemName,
                  },
                ]}
                isClearable={true}
                inputClassName='lineItemSelect'
                key={`${fieldPrefix}.items.${index}.lineItemId`}
                name={`${fieldPrefix}.items.${index}.lineItemId`}
                isOptionDisabled={isOptionDisabled}
                disabled={isLocked}
                formatOptionLabel={(option, menuIsOpen) => {
                  // get if controller is focused
                  if (option.value === 'add_new' && newLineItemName.length > 0) {
                    return <span style={{ fontStyle: 'italic' }}>{option.label}</span>;
                  } else if (option.value === 'add_new' && newLineItemName.length == 0) {
                    return <span style={{ fontStyle: 'italic' }}>{lastNewLineItemName}</span>;
                  }

                  return renderLineItemLabel(option, menuIsOpen);
                }}
                onInputChange={(inputValue) => {
                  inputValue = inputValue.trim();
                  setNewLineItemName(inputValue);
                  if (inputValue.length > 0) {
                    setLastNewLineItemName(inputValue);
                  }
                }}
                onChange={(e) => {
                  if (e.value === 'add_new') {
                    setShowEditModal(true);
                  } else {
                    const lineItem = lineItemOptions.find((option) => option._id === e.value);

                    setValue(
                      `${fieldPrefix}.items.${index}.description`,
                      lineItem?.definition,
                      setValueOptions
                    );
                    setValue(
                      `${fieldPrefix}.items.${index}.unitCost`,
                      lineItem?.unitCost,
                      setValueOptions
                    );
                    setValue(
                      `${fieldPrefix}.items.${index}.unitPrice`,
                      lineItem?.unitPrice,
                      setValueOptions
                    );
                    setValue(
                      `${fieldPrefix}.items.${index}.qbItemId`,
                      lineItem?.qbItemId,
                      setValueOptions
                    );
                    setValue(
                      `${fieldPrefix}.items.${index}.taxable`,
                      lineItem?.taxable,
                      setValueOptions
                    );
                    setValue(
                      `${fieldPrefix}.items.${index}.billable`,
                      lineItem?.billable,
                      setValueOptions
                    );
                    setValue(`${fieldPrefix}.items.${index}.serviceDate`, null, setValueOptions);

                    let currentQty = currencyToNumber(scopeEst.items[index].qty);

                    if (currentQty === 0) {
                      currentQty = currencyToNumber(lineItem?.defaultQuantity ?? '1');
                      setValue(
                        `${fieldPrefix}.items.${index}.qty`,
                        currentQty.toString(),
                        setValueOptions
                      );
                    }

                    const unitCost = currencyToNumber(lineItem?.unitCost);
                    const unitPrice = currencyToNumber(lineItem?.unitPrice);

                    const totalCost = currentQty * unitCost;
                    const total = currentQty * unitPrice;
                    const markup = unitPrice - unitCost;
                    const markupPercent = unitCost === 0 ? 0 : (markup / unitCost) * 100;

                    setValue(
                      `${fieldPrefix}.items.${index}.cost`,
                      formatMoney(totalCost, -1, ''),
                      setValueOptions
                    );
                    setValue(
                      `${fieldPrefix}.items.${index}.total`,
                      formatMoneyLocal(total),
                      setValueOptions
                    );
                    setValue(
                      `${fieldPrefix}.items.${index}.profit`,
                      formatMoneyLocal(markup),
                      setValueOptions
                    );
                    setValue(
                      `${fieldPrefix}.items.${index}.profitPers`,
                      markupPercent.toFixed(1),
                      setValueOptions
                    );

                    recalculateTotals(itemIndex);
                  }
                }}
              />
              {isLocked && (
                <div className='ms-2'>
                  {
                    allLineItemSelectOptions.find(
                      (option) => option.value === estData[index].lineItemId
                    )?.label
                  }
                </div>
              )}
            </div>
            {detailedMode && visibleColumns.description && (
              // set textArea to false if being dragged
              <FormInput
                type='text'
                inputClassName={`form-control text-start`}
                className={formInputClassLong}
                control={control}
                key={`${fieldPrefix}.items.${index}.description`}
                name={`${fieldPrefix}.items.${index}.description`}
                placeholder='Description'
                disabled={isLocked}
                textArea={textAreaEnabled}
                autoSize={{ minRows: 1, maxRows: 8 }}
              />
            )}
            {detailedMode && visibleColumns.serviceDate && (
              <FormInput
                type='date'
                inputClassName={`form-control text-start `}
                className={formInputClass}
                control={control}
                key={`${fieldPrefix}.items.${index}.serviceDate`}
                name={`${fieldPrefix}.items.${index}.serviceDate`}
                placeholder='Service Date'
                disabled={isLocked}
              />
            )}
            {detailedMode && visibleColumns.qty && (
              <FormInput
                type='number'
                inputClassName={`form-control text-end `}
                className={formInputClassShort}
                control={control}
                key={`${fieldPrefix}.items.${index}.qty`}
                name={`${fieldPrefix}.items.${index}.qty`}
                placeholder='Qty'
                disabled={isLocked}
                onChange={(e) => {
                  const qty = currencyToNumber(e);
                  const unitCost = currencyToNumber(scopeEst.items[index].unitCost);
                  const unitPrice = currencyToNumber(scopeEst.items[index].unitPrice);

                  const totalCost = qty * unitCost;
                  const total = qty * unitPrice;

                  //setValue(`${fieldPrefix}.items.${index}.qty`, qty, setValueOptions);
                  setValue(
                    `${fieldPrefix}.items.${index}.cost`,
                    formatMoneyLocal(totalCost),
                    setValueOptions
                  );
                  setValue(
                    `${fieldPrefix}.items.${index}.total`,
                    formatMoneyLocal(total),
                    setValueOptions
                  );

                  recalculateTotals(itemIndex);
                }}
              />
            )}
            {detailedMode && visibleColumns.unitCost && (
              <FormInput
                type='number'
                inputClassName={`form-control text-end `}
                className={formInputClass}
                control={control}
                key={`${fieldPrefix}.items.${index}.unitCost`}
                name={`${fieldPrefix}.items.${index}.unitCost`}
                placeholder='Unit Cost'
                disabled={isLocked}
                CustomInput={CurrencyInput}
                onChange={(e) => {
                  const unitCost = currencyToNumber(e);
                  const qty = currencyToNumber(scopeEst.items[index].qty);

                  if (isTotalLocked) {
                    const total = currencyToNumber(scopeEst.items[index].total);
                    const unitPrice = total / qty;

                    const markup = unitPrice - unitCost;
                    const markupPercent = unitCost === 0 ? 0 : (markup / unitCost) * 100;

                    const totalCost = qty * unitCost;

                    setValue(
                      `${fieldPrefix}.items.${index}.cost`,
                      formatMoneyLocal(totalCost),
                      setValueOptions
                    );
                    setValue(
                      `${fieldPrefix}.items.${index}.profit`,
                      formatMoneyLocal(markup),
                      setValueOptions
                    );
                    setValue(
                      `${fieldPrefix}.items.${index}.profitPers`,
                      markupPercent.toFixed(1),
                      setValueOptions
                    );
                    setValue(
                      `${fieldPrefix}.items.${index}.unitPrice`,
                      formatMoneyLocal(unitPrice),
                      setValueOptions
                    );
                  } else {
                    const markup = showProfitAsPers
                      ? (currencyToNumber(scopeEst.items[index].profitPers) / 100) * unitCost
                      : currencyToNumber(scopeEst.items[index].profit);

                    const unitPrice = unitCost + markup;

                    const totalCost = qty * unitCost;
                    const total = qty * unitPrice;

                    setValue(
                      `${fieldPrefix}.items.${index}.cost`,
                      formatMoneyLocal(totalCost),
                      setValueOptions
                    );
                    setValue(
                      `${fieldPrefix}.items.${index}.unitPrice`,
                      formatMoneyLocal(unitPrice),
                      setValueOptions
                    );
                    setValue(
                      `${fieldPrefix}.items.${index}.total`,
                      formatMoneyLocal(total),
                      setValueOptions
                    );
                  }

                  recalculateTotals(itemIndex);
                }}
              />
            )}
            {detailedMode && visibleColumns.cost && (
              <FormInput
                type='number'
                inputClassName={`form-control text-end `}
                className={formInputClass}
                control={control}
                key={`${fieldPrefix}.items.${index}.cost`}
                name={`${fieldPrefix}.items.${index}.cost`}
                placeholder='Cost'
                disabled={isLocked}
                CustomInput={CurrencyInput}
                onChange={(e) => {
                  const cost = currencyToNumber(e);
                  const qty = currencyToNumber(scopeEst.items[index].qty);
                  const unitCost = cost / qty;
                  //const unitCost = currencyToNumber(scopeEst.items[index].unitCost);

                  if (isTotalLocked) {
                    const total = currencyToNumber(scopeEst.items[index].total);
                    const unitPrice = total / qty;
                    const markup = unitPrice - unitCost;
                    const markupPercent = unitCost === 0 ? 0 : (markup / unitCost) * 100;

                    setValue(
                      `${fieldPrefix}.items.${index}.unitCost`,
                      formatMoneyLocal(unitCost),
                      setValueOptions
                    );
                    setValue(
                      `${fieldPrefix}.items.${index}.unitPrice`,
                      formatMoneyLocal(unitPrice),
                      setValueOptions
                    );
                    setValue(
                      `${fieldPrefix}.items.${index}.profit`,
                      formatMoneyLocal(markup),
                      setValueOptions
                    );
                    setValue(
                      `${fieldPrefix}.items.${index}.profitPers`,
                      markupPercent.toFixed(1),
                      setValueOptions
                    );
                  } else {
                    const markup = showProfitAsPers
                      ? (currencyToNumber(scopeEst.items[index].profitPers) / 100) * unitCost
                      : currencyToNumber(scopeEst.items[index].profit);

                    const unitPrice = unitCost + markup;

                    const total = qty * unitPrice;

                    setValue(
                      `${fieldPrefix}.items.${index}.unitCost`,
                      formatMoneyLocal(unitCost),
                      setValueOptions
                    );
                    setValue(
                      `${fieldPrefix}.items.${index}.unitPrice`,
                      formatMoneyLocal(unitPrice),
                      setValueOptions
                    );

                    setValue(
                      `${fieldPrefix}.items.${index}.total`,
                      formatMoneyLocal(total),
                      setValueOptions
                    );
                  }

                  recalculateTotals(itemIndex);
                }}
              />
            )}
            {detailedMode &&
              visibleColumns.markup &&
              (showProfitAsPers ? (
                <FormInput
                  type='number'
                  inputClassName={`form-control text-end `}
                  className={`${formInputClass} ${styles.percentage} `}
                  control={control}
                  key={`${fieldPrefix}.items.${index}.profitPers`}
                  name={`${fieldPrefix}.items.${index}.profitPers`}
                  placeholder=''
                  disabled={isLocked}
                  onChange={(e) => {
                    const profitPers = currencyToNumber(e);
                    const qty = currencyToNumber(scopeEst.items[index].qty);

                    if (isTotalLocked) {
                      const unitPrice = currencyToNumber(scopeEst.items[index].unitPrice);
                      const unitCost = unitPrice / (1 + profitPers / 100);
                      const totalCost = qty * unitCost;

                      setValue(
                        `${fieldPrefix}.items.${index}.unitCost`,
                        formatMoneyLocal(unitCost),
                        setValueOptions
                      );
                      setValue(
                        `${fieldPrefix}.items.${index}.cost`,
                        formatMoneyLocal(totalCost),
                        setValueOptions
                      );
                    } else {
                      const unitCost = currencyToNumber(scopeEst.items[index].unitCost);
                      const markup = (profitPers / 100) * unitCost;
                      const unitPrice = unitCost + markup;

                      const total = qty * unitPrice;

                      setValue(
                        `${fieldPrefix}.items.${index}.profit`,
                        formatMoneyLocal(markup),
                        setValueOptions
                      );
                      setValue(
                        `${fieldPrefix}.items.${index}.unitPrice`,
                        formatMoneyLocal(unitPrice),
                        setValueOptions
                      );
                      setValue(
                        `${fieldPrefix}.items.${index}.total`,
                        formatMoneyLocal(total),
                        setValueOptions
                      );
                    }

                    recalculateTotals(itemIndex);
                  }}
                />
              ) : (
                <FormInput
                  type='number'
                  inputClassName={`form-control text-end `}
                  className={formInputClass}
                  control={control}
                  key={`${fieldPrefix}.items.${index}.profit`}
                  name={`${fieldPrefix}.items.${index}.profit`}
                  placeholder='Profit ($)'
                  disabled={isLocked}
                  CustomInput={CurrencyInput}
                  onChange={(e) => {
                    const profit = currencyToNumber(e);
                    const qty = currencyToNumber(scopeEst.items[index].qty);

                    if (isTotalLocked) {
                      const unitPrice = currencyToNumber(scopeEst.items[index].unitPrice);
                      const unitCost = unitPrice - profit;
                      const profitPers = unitCost === 0 ? 0 : (profit / unitCost) * 100;

                      const totalCost = qty * unitCost;

                      setValue(
                        `${fieldPrefix}.items.${index}.profitPers`,
                        profitPers.toFixed(1),
                        setValueOptions
                      );
                      setValue(
                        `${fieldPrefix}.items.${index}.unitCost`,
                        formatMoneyLocal(unitCost),
                        setValueOptions
                      );
                      setValue(
                        `${fieldPrefix}.items.${index}.cost`,
                        formatMoneyLocal(totalCost),
                        setValueOptions
                      );
                    } else {
                      const unitCost = currencyToNumber(scopeEst.items[index].unitCost);
                      const unitPrice = unitCost + profit;

                      const total = qty * unitPrice;

                      setValue(
                        `${fieldPrefix}.items.${index}.unitPrice`,
                        formatMoneyLocal(unitPrice),
                        setValueOptions
                      );
                      setValue(
                        `${fieldPrefix}.items.${index}.total`,
                        formatMoneyLocal(total),
                        setValueOptions
                      );
                    }

                    recalculateTotals(itemIndex);
                  }}
                />
              ))}
            {detailedMode && visibleColumns.unitPrice && (
              <FormInput
                type='number'
                inputClassName={`form-control text-end `}
                className={formInputClass}
                control={control}
                key={`${fieldPrefix}.items.${index}.unitPrice`}
                name={`${fieldPrefix}.items.${index}.unitPrice`}
                placeholder='Unit Price'
                disabled={isLocked || isTotalLocked}
                CustomInput={CurrencyInput}
                onChange={(e) => {
                  const unitPrice = currencyToNumber(e);
                  const qty = currencyToNumber(scopeEst.items[index].qty);

                  const unitCost = currencyToNumber(scopeEst.items[index].unitCost);
                  const markup = unitPrice - unitCost;

                  const markupPercent = unitCost === 0 ? 0 : (markup / unitCost) * 100;

                  const total = qty * unitPrice;

                  setValue(
                    `${fieldPrefix}.items.${index}.profitPers`,
                    markupPercent.toFixed(1),
                    setValueOptions
                  );
                  setValue(
                    `${fieldPrefix}.items.${index}.profit`,
                    formatMoneyLocal(markup),
                    setValueOptions
                  );
                  setValue(
                    `${fieldPrefix}.items.${index}.total`,
                    formatMoneyLocal(total),
                    setValueOptions
                  );

                  recalculateTotals(itemIndex);
                }}
              />
            )}
            {(!detailedMode || visibleColumns.total) && (
              <FormInput
                type='number'
                inputClassName={`form-control text-end `}
                className={formInputClass}
                control={control}
                key={`${fieldPrefix}.items.${index}.total`}
                name={`${fieldPrefix}.items.${index}.total`}
                placeholder='Total'
                disabled={isLocked || isTotalLocked}
                CustomInput={CurrencyInput}
                onChange={(e) => {
                  const total = currencyToNumber(e);
                  const qty = currencyToNumber(scopeEst.items[index].qty);
                  const unitCost = currencyToNumber(scopeEst.items[index].unitCost);

                  const profit = total - qty * unitCost;
                  const unitPrice = total / qty;
                  const markupPercent = unitCost === 0 ? 0 : (profit / unitCost) * 100;

                  setValue(
                    `${fieldPrefix}.items.${index}.profit`,
                    formatMoneyLocal(profit),
                    setValueOptions
                  );
                  setValue(
                    `${fieldPrefix}.items.${index}.unitPrice`,
                    formatMoneyLocal(unitPrice),
                    setValueOptions
                  );
                  setValue(
                    `${fieldPrefix}.items.${index}.profitPers`,
                    markupPercent.toFixed(1),
                    setValueOptions
                  );

                  recalculateTotals(itemIndex);
                }}
              />
            )}
            {detailedMode && visibleColumns.taxable && (
              <FormInput
                type='checkbox'
                inputClassName={`form-check-input  ` + styles.checkbox}
                className={formInputClassExtraShort}
                control={control}
                key={`${fieldPrefix}.items.${index}.taxable`}
                name={`${fieldPrefix}.items.${index}.taxable`}
                disabled={isLocked}
              />
            )}
            {detailedMode && visibleColumns.billable && (
              <FormInput
                type='checkbox'
                inputClassName={`form-check-input  ` + styles.checkbox}
                className={formInputClassExtraShort}
                control={control}
                key={`${fieldPrefix}.items.${index}.billable`}
                name={`${fieldPrefix}.items.${index}.billable`}
                disabled={isLocked}
              />
            )}

            {!isLocked && (
              <div className={`px-1 ${styles.controlBtn} me-2`}>
                {!isLocked && detailedMode && isGroupItem ? (
                  <div className=''>
                    <a className='ms-1' onClick={() => onAddLineItem(index + 1)}>
                      <i
                        className={`fa-solid fa-plus-circle ${styles.addButton}`}
                        aria-hidden='true'
                        title='Add line item'
                      ></i>
                    </a>
                  </div>
                ) : (
                  <div className={`ms-4`}></div>
                )}
                <a onClick={() => onRemoveLineItem(fieldPrefix, index)}>
                  <i
                    className={`fa-solid fa-circle-xmark ${styles.deleteButton}`}
                    aria-hidden='true'
                    title='Remove line item'
                  ></i>
                </a>
              </div>
            )}
          </div>
        )}
      </Draggable>
    );
  };

  const onAddLineItem = (at_index = 0) => {
    const newLineItem = {
      lineItemId: '',
      description: '',
      qty: 1,
      unitCost: 0,
      cost: 0,
      profit: 0,
      profitPers: 0,
      unitPrice: 0,
      total: 0,
      qbItemId: '',
      taxable: false,
      billable: true,
      serviceDate: null,
      scopeId: '',
    };

    if (at_index < 0 || !scopeEst.items || at_index > scopeEst.items.length) {
      setValue(`${fieldPrefix}.items`, [...(scopeEst.items ?? []), newLineItem], setValueOptions);
    } else {
      const newEstData = _.cloneDeep(scopeEst.items);
      newEstData.splice(at_index, 0, newLineItem);
      setValue(`${fieldPrefix}.items`, newEstData, setValueOptions);
      updateEstDataSubFields(newEstData);
    }

    recalculateTotals(itemIndex);
  };

  const hasLineItems = scopeEst.items && scopeEst.items.length > 0;

  const isGroupItem =
    lineItemOptions.find((option) => option._id === scopeEst.lineItemId)?.type === 'Group';

  if (scopeEst.items && scopeEst.items.length > 0 && !isGroupItem) {
    setValue(`${fieldPrefix}.items`, [], setValueOptions);
  }

  const [lineItemLabel, setLineItemLabel] = useState('');

  return (
    <>
      {isGroupItem && <br></br>}
      <div className={'row flex-nowrap col-md-12'} data-testId='bidManagementModalScope'>
        {showEditModal && (
          <EditSettingModal
            open={showEditModal}
            onClose={() => setShowEditModal(false)}
            settingId={null}
            onSubmit={(setting: any) => {
              setShowEditModal(false);

              if (setting.name.length > 0) {
                // refresh line item options
                onAddNewLineItem({
                  name: setting.name,
                  definition: setting.definition,
                  code: setting.code,
                });
              }
            }}
            initialValues={{ name: lastNewLineItemName, jobScopeId: scopeEst.scopeId }}
            jobScopes={options.map((option) => ({ _id: option.value, jobScopeName: option.label }))}
          />
        )}
        {detailedMode && !isLocked && (
          <div {...dragHandleProps} className={`px-1 ${styles.dragHandle} ms-0 mb-0`}>
            <FontAwesomeIcon icon={faGripVertical} />
          </div>
        )}
        <div
          className={`px-1  ${itemLabelStyle} ${isScopeDisabled || isLocked ? styles.disabledSelect : ''}`}
        >
          {lineItemOptions?.length > 0 ? (
            !isLocked ? (
              <div>
                <div className='ms-2'>
                  <FormSelect
                    label={''}
                    control={control}
                    options={[
                      ...allLineItemSelectOptions, //...!hasLineItems ? allLineItemSelectOptions : bundleLineItemSelectOptions,
                      {
                        value: 'add_new',
                        label: `Add "${newLineItemName}"`,
                        isDisabled: !newLineItemName,
                        scopeId: scopeEst.scopeId,
                      },
                    ]}
                    isClearable={true}
                    inputClassName='lineItemSelect'
                    key={`${fieldPrefix}.lineItemId`}
                    name={`${fieldPrefix}.lineItemId`}
                    isOptionDisabled={isOptionDisabled}
                    disabled={isLocked}
                    formatOptionLabel={(option, menuIsOpen) => {
                      // get if controller is focused
                      if (option.value === 'add_new' && newLineItemName.length > 0) {
                        return <span style={{ fontStyle: 'italic' }}>{option.label}</span>;
                      } else if (option.value === 'add_new' && newLineItemName.length == 0) {
                        return <span style={{ fontStyle: 'italic' }}>{lastNewLineItemName}</span>;
                      }

                      return renderLineItemLabel(option, menuIsOpen);
                    }}
                    onInputChange={(inputValue) => {
                      inputValue = inputValue.trim();
                      setNewLineItemName(inputValue);
                      if (inputValue.length > 0) {
                        setLastNewLineItemName(inputValue);
                      }
                    }}
                    onChange={(e) => {
                      if (!e) {
                        setValue(`${fieldPrefix}.description`, '', setValueOptions);
                        setValue(`${fieldPrefix}.scopeId`, '', setValueOptions);
                        return;
                      }
                      if (e.value === 'add_new') {
                        setShowEditModal(true);
                      } else {
                        const lineItem = lineItemOptions.find((option) => option._id === e.value);

                        //setValue(`${fieldPrefix}.lineItemId`, lineItem._id, setValueOptions);
                        setValue(
                          `${fieldPrefix}.description`,
                          lineItem?.definition,
                          setValueOptions
                        );
                        setValue(`${fieldPrefix}.unitCost`, lineItem?.unitCost, setValueOptions);
                        setValue(`${fieldPrefix}.unitPrice`, lineItem?.unitPrice, setValueOptions);
                        setValue(`${fieldPrefix}.qbItemId`, lineItem?.qbItemId, setValueOptions);
                        setValue(`${fieldPrefix}.taxable`, lineItem?.taxable, setValueOptions);
                        setValue(`${fieldPrefix}.billable`, lineItem?.billable, setValueOptions);
                        setValue(`${fieldPrefix}.serviceDate`, null, setValueOptions);
                        setValue(`${fieldPrefix}.scopeId`, lineItem?.jobScopeId, setValueOptions);

                        let currentQty = currencyToNumber(scopeEst.qty);

                        if (currentQty === 0) {
                          currentQty = currencyToNumber(lineItem?.defaultQuantity ?? '1');
                          setValue(`${fieldPrefix}.qty`, currentQty.toString(), setValueOptions);
                        }

                        const itemGroupDetails = lineItem?.itemGroupDetails;

                        if (itemGroupDetails && lineItem.type === 'Group') {
                          const newSubItems = [] as any[];

                          itemGroupDetails.forEach((subItem) => {
                            // match based on with line item based on qbId and add to subItems

                            const subLineItem = lineItemOptions.find(
                              (option) => option.qbItemId === subItem.qbId
                            );
                            const subLineItemQty = currencyToNumber(subItem.qty);

                            if (subLineItem) {
                              newSubItems.push({
                                lineItemId: subLineItem._id,
                                description: subLineItem.definition,
                                qty: subLineItemQty.toString(),
                                unitCost: subLineItem.unitCost,
                                cost: formatMoneyLocal(subLineItem.unitCost * subLineItemQty),
                                unitPrice: subLineItem.unitPrice,
                                total: formatMoneyLocal(subLineItem.unitPrice * subLineItemQty),
                                profit: formatMoneyLocal(
                                  subLineItem.unitPrice * subLineItemQty -
                                    subLineItem.unitCost * subLineItemQty
                                ),
                                profitPers: (
                                  ((subLineItem.unitPrice * subLineItemQty -
                                    subLineItem.unitCost * subLineItemQty) /
                                    subLineItem.unitCost) *
                                  100
                                ).toFixed(1),
                                subTotalCtr: formatMoneyLocal(
                                  subLineItem.unitPrice * subLineItemQty
                                ),
                                qbItemId: subLineItem.qbItemId,
                                taxable: subLineItem.taxable,
                                billable: subLineItem.billable,
                                serviceDate: null,
                              });
                            }
                          });
                          setValue(`${fieldPrefix}.items`, newSubItems, setValueOptions);
                        }

                        const unitCost = currencyToNumber(lineItem?.unitCost);
                        const unitPrice = currencyToNumber(lineItem?.unitPrice);

                        const totalCost = currentQty * unitCost;
                        const total = currentQty * unitPrice;
                        const markup = unitPrice - unitCost;
                        //const markupPercent = unitCost === 0 ? 0 : (markup / unitCost) * 100;

                        //setValue(`${fieldPrefix}.cost`, formatMoneyLocal(totalCost), setValueOptions);
                        //setValue(`${fieldPrefix}.total`, formatMoneyLocal(total), setValueOptions);
                        //setValue(`${fieldPrefix}.profit`, formatMoneyLocal(markup), setValueOptions);
                        //setValue(`${fieldPrefix}.profitPers`, markupPercent.toFixed(1), setValueOptions);
                        setValue(
                          `${fieldPrefix}.subTotalCtr`,
                          formatMoneyLocal(total),
                          setValueOptions
                        );
                        setValue(
                          `${fieldPrefix}.subTotalCost`,
                          formatMoneyLocal(totalCost),
                          setValueOptions
                        );
                        setValue(
                          `${fieldPrefix}.subTotalProfit`,
                          formatMoneyLocal(markup),
                          setValueOptions
                        );
                        setValue(
                          `${fieldPrefix}.unitPrice`,
                          formatMoneyLocal(unitPrice),
                          setValueOptions
                        );
                        setValue(
                          `${fieldPrefix}.unitCost`,
                          formatMoneyLocal(unitCost),
                          setValueOptions
                        );

                        recalculateTotals(itemIndex);
                      }
                    }}
                  />
                </div>
                {isLocked && (
                  <div className='ms-0 mt-2'>
                    {
                      lineItemLabel
                    }
                  </div>
                )}
              </div>
            ) : (
              <div className='ms-2 mt-2'>
                {
                  lineItemLabel
                }
              </div>
            )
          ) : !isLocked ? (
            <>
              <FormSelect
                label='JOBSCOPEMODE'
                control={control}
                options={options}
                key={`${fieldPrefix}.scopeId`}
                name={`${fieldPrefix}.scopeId`}
                isOptionDisabled={isOptionDisabled}
                disabled={isScopeDisabled || isLocked}
                onChange={(e) => {
                  const scope = allOptions.find((option) => option.value === e.value);

                  //setValue(`${fieldPrefix}.scopeId`, e.value, setValueOptions);
                  setValue(`${fieldPrefix}.lineItemId`, scope.lineItemId, setValueOptions);
                  setValue(`${fieldPrefix}.description`, scope.label, setValueOptions);
                  setValue(`${fieldPrefix}.unitCost`, scope.unitCost, setValueOptions);
                  setValue(`${fieldPrefix}.unitPrice`, scope.unitPrice, setValueOptions);
                  setValue(`${fieldPrefix}.qbItemId`, scope.qbItemId, setValueOptions);
                  setValue(`${fieldPrefix}.taxable`, scope.taxable, setValueOptions);
                  setValue(`${fieldPrefix}.billable`, scope.billable, setValueOptions);
                  setValue(`${fieldPrefix}.serviceDate`, null, setValueOptions);

                  let currentQty = currencyToNumber(scopeEst.qty);

                  if (currentQty === 0) {
                    currentQty = currencyToNumber(scope.defaultQuantity ?? '1');
                    setValue(`${fieldPrefix}.qty`, currentQty.toString(), setValueOptions);
                  }

                  const unitCost = currencyToNumber(scope.unitCost);
                  const unitPrice = currencyToNumber(scope.unitPrice);

                  const totalCost = currentQty * unitCost;
                  const total = currentQty * unitPrice;
                  const markup = unitPrice - unitCost;
                  const markupPercent = unitCost === 0 ? 0 : (markup / unitCost) * 100;

                  setValue(
                    `${fieldPrefix}.subTotalCost`,
                    formatMoneyLocal(totalCost),
                    setValueOptions
                  );
                  setValue(`${fieldPrefix}.subTotalCtr`, formatMoneyLocal(total), setValueOptions);
                  setValue(
                    `${fieldPrefix}.subTotalProfit`,
                    formatMoneyLocal(markup),
                    setValueOptions
                  );
                  setValue(
                    `${fieldPrefix}.unitPrice`,
                    formatMoneyLocal(unitPrice),
                    setValueOptions
                  );
                  setValue(`${fieldPrefix}.unitCost`, formatMoneyLocal(unitCost), setValueOptions);
                  setValue(
                    `${fieldPrefix}.subTotalProfitPers`,
                    markupPercent.toFixed(1),
                    setValueOptions
                  );
                }}
              />
            </>
          ) : (
            <div className='ms-2'>
              {
                allOptions.find((option) => option.value === estData[itemIndex].lineItemId)
                  ?.label
              }
            </div>
          )}
        </div>
        {detailedMode && visibleColumns.description && (
          <FormInput
            type='text'
            inputClassName={`form-control text-start `}
            className={formInputClassLong}
            control={control}
            key={`${fieldPrefix}.description`}
            name={`${fieldPrefix}.description`}
            placeholder='Description'
            disabled={isLocked}
            textArea={textAreaEnabled}
            autoSize={{ minRows: 1, maxRows: 8 }}
          />
        )}
        {detailedMode &&
          visibleColumns.serviceDate &&
          (!isGroupItem ? (
            <FormInput
              type='date'
              inputClassName={`form-control text-start `}
              className={formInputClass}
              control={control}
              key={`${fieldPrefix}.serviceDate`}
              name={`${fieldPrefix}.serviceDate`}
              placeholder='Service Date'
              disabled={isLocked}
            />
          ) : (
            <div className={formInputClass}></div>
          ))}
        {detailedMode && visibleColumns.qty && (
          <FormInput
            type='number'
            inputClassName={`form-control text-end `}
            className={formInputClassShort}
            control={control}
            key={`${fieldPrefix}.qty`}
            name={`${fieldPrefix}.qty`}
            placeholder='Qty'
            disabled={isLocked}
            onChange={(e) => {
              const qty = currencyToNumber(e);

              if (scopeEst.items && scopeEst.items.length > 0) {
                if (qty === 0) {
                  return;
                }
                const subTotalCost = currencyToNumber(scopeEst.subTotalCost);
                const unitCost = currencyToNumber(scopeEst.unitCost);
                const subTotalCtr = currencyToNumber(scopeEst.subTotalCtr);
                const unitPrice = currencyToNumber(scopeEst.unitPrice);

                const formerQty = unitCost > 0 ? subTotalCost / unitCost : subTotalCtr / unitPrice;
                const changeInQty = qty / formerQty;

                //setValue(`${fieldPrefix}.qty`, e, setValueOptions);

                // recalculate all line item quantities
                const newItems = scopeEst.items.map((item: any) => {
                  const newQty = currencyToNumber(item.qty) * changeInQty;
                  const newCost = currencyToNumber(item.cost) * changeInQty;
                  const newTotal = currencyToNumber(item.total) * changeInQty;

                  return {
                    ...item,
                    qty: newQty.toString(),
                    cost: formatMoneyLocal(newCost),
                    total: formatMoneyLocal(newTotal),
                  };
                });

                newItems.map((item: any, index: number) => {
                  setValue(`${fieldPrefix}.items.${index}.qty`, item.qty, setValueOptions);
                  setValue(`${fieldPrefix}.items.${index}.cost`, item.cost, setValueOptions);
                  setValue(`${fieldPrefix}.items.${index}.total`, item.total, setValueOptions);
                });

                recalculateTotals(itemIndex);
              } else {
                const unitCost = currencyToNumber(scopeEst.unitCost);
                const unitPrice = currencyToNumber(scopeEst.unitPrice);

                const totalCost = qty * unitCost;
                const total = qty * unitPrice;

                const profit = total - totalCost;

                setValue(`${fieldPrefix}.qty`, e, setValueOptions);
                setValue(
                  `${fieldPrefix}.subTotalCost`,
                  formatMoneyLocal(totalCost),
                  setValueOptions
                );
                setValue(`${fieldPrefix}.subTotalCtr`, formatMoneyLocal(total), setValueOptions);
                setValue(
                  `${fieldPrefix}.subTotalProfit`,
                  formatMoneyLocal(profit),
                  setValueOptions
                );

                recalculateTotals(itemIndex);
              }
            }}
          />
        )}
        {detailedMode && visibleColumns.unitCost && (
          <FormInput
            type='number'
            inputClassName={`form-control text-end `}
            className={formInputClass}
            control={control}
            key={`${fieldPrefix}.unitCost`}
            name={`${fieldPrefix}.unitCost`}
            placeholder='Unit Cost'
            disabled={isLocked || hasLineItems || isGroupItem}
            CustomInput={CurrencyInput}
            onChange={(e) => {
              const unitCost = currencyToNumber(e);
              const qty = currencyToNumber(scopeEst.qty);

              if (isTotalLocked) {
                const total = currencyToNumber(scopeEst.subTotalCtr);
                const unitPrice = total / qty;

                const markup = unitPrice - unitCost;
                const markupPercent = unitCost === 0 ? 0 : (markup / unitCost) * 100;

                const totalCost = qty * unitCost;
                const totalProfit = qty * markup;

                setValue(
                  `${fieldPrefix}.subTotalCost`,
                  formatMoneyLocal(totalCost),
                  setValueOptions
                );
                setValue(
                  `${fieldPrefix}.subTotalProfit`,
                  formatMoneyLocal(totalProfit),
                  setValueOptions
                );
                setValue(
                  `${fieldPrefix}.subTotalProfitPers`,
                  markupPercent.toFixed(1),
                  setValueOptions
                );
                setValue(`${fieldPrefix}.unitPrice`, formatMoneyLocal(unitPrice), setValueOptions);
                setValue(`${fieldPrefix}.unitProfit`, formatMoneyLocal(markup), setValueOptions);
              } else {
                const markup = showProfitAsPers
                  ? (currencyToNumber(scopeEst.subTotalProfitPers) / 100) * unitCost
                  : currencyToNumber(scopeEst.subTotalProfit);
                const unitPrice = unitCost + markup;
                const total = qty * unitPrice;
                const totalCost = qty * unitCost;
                const totalProfit = qty * markup;

                setValue(
                  `${fieldPrefix}.subTotalCost`,
                  formatMoneyLocal(totalCost),
                  setValueOptions
                );
                setValue(
                  `${fieldPrefix}.subTotalProfit`,
                  formatMoneyLocal(totalProfit),
                  setValueOptions
                );
                setValue(`${fieldPrefix}.subTotalCtr`, formatMoneyLocal(total), setValueOptions);
                setValue(`${fieldPrefix}.unitPrice`, formatMoneyLocal(unitPrice), setValueOptions);
                setValue(`${fieldPrefix}.unitProfit`, formatMoneyLocal(markup), setValueOptions);
              }

              recalculateTotals(itemIndex);
            }}
          />
        )}
        {(!detailedMode || visibleColumns.cost) && (
          <FormInput
            type='number'
            inputClassName={`form-control text-end `}
            className={formInputClass}
            control={control}
            key={`${fieldPrefix}.subTotalCost`}
            name={`${fieldPrefix}.subTotalCost`}
            placeholder='Cost'
            disabled={isLocked || hasLineItems || isGroupItem}
            CustomInput={CurrencyInput}
            onChange={(e) => {
              const cost = currencyToNumber(e);
              const qty = currencyToNumber(scopeEst.qty);
              const unitCost = cost / qty;

              if (isTotalLocked) {
                const total = currencyToNumber(scopeEst.subTotalCtr);
                const unitPrice = total / qty;
                const markup = unitPrice - unitCost;
                const markupPercent = unitCost === 0 ? 0 : (markup / unitCost) * 100;
                const totalProfit = qty * markup;

                setValue(`${fieldPrefix}.unitCost`, formatMoneyLocal(unitCost), setValueOptions);
                setValue(`${fieldPrefix}.unitPrice`, formatMoneyLocal(unitPrice), setValueOptions);
                setValue(`${fieldPrefix}.unitProfit`, formatMoneyLocal(markup), setValueOptions);
                setValue(
                  `${fieldPrefix}.subTotalProfit`,
                  formatMoneyLocal(totalProfit),
                  setValueOptions
                );
                setValue(
                  `${fieldPrefix}.subTotalProfitPers`,
                  markupPercent.toFixed(1),
                  setValueOptions
                );
              } else {
                const markup = showProfitAsPers
                  ? (currencyToNumber(scopeEst.subTotalProfitPers) / 100) * unitCost
                  : currencyToNumber(scopeEst.subTotalProfit);
                const unitPrice = unitCost + markup;
                const total = qty * unitPrice;
                const totalProfit = qty * markup;

                if (showProfitAsPers) {
                  setValue(
                    `${fieldPrefix}.subTotalProfit`,
                    formatMoneyLocal(totalProfit),
                    setValueOptions
                  );
                  setValue(`${fieldPrefix}.unitProfit`, formatMoneyLocal(markup), setValueOptions);
                } else {
                  const markupPercent = unitCost === 0 ? 0 : (markup / unitCost) * 100;
                  setValue(
                    `${fieldPrefix}.subTotalProfitPers`,
                    markupPercent.toFixed(1),
                    setValueOptions
                  );
                }

                setValue(`${fieldPrefix}.unitCost`, formatMoneyLocal(unitCost), setValueOptions);
                setValue(`${fieldPrefix}.unitPrice`, formatMoneyLocal(unitPrice), setValueOptions);
                setValue(`${fieldPrefix}.subTotalCtr`, formatMoneyLocal(total), setValueOptions);
              }

              recalculateTotals(itemIndex);
            }}
          />
        )}
        {(!detailedMode || visibleColumns.markup) &&
          (showProfitAsPers ? (
            <FormInput
              type='number'
              inputClassName={`form-control text-end `}
              className={`${formInputClass} ${styles.percentage} `}
              control={control}
              key={`${fieldPrefix}.subTotalProfitPers`}
              name={`${fieldPrefix}.subTotalProfitPers`}
              placeholder=''
              disabled={isLocked || hasLineItems || isGroupItem}
              onChange={(e) => {
                const profitPers = currencyToNumber(e);
                const qty = currencyToNumber(scopeEst.qty);

                if (isTotalLocked) {
                  const unitPrice = currencyToNumber(scopeEst.unitPrice);
                  const unitCost = unitPrice / (1 + profitPers / 100);
                  const profit = unitPrice - unitCost;
                  const totalCost = qty * unitCost;
                  const totalProfit = qty * profit;

                  setValue(
                    `${fieldPrefix}.subTotalProfit`,
                    formatMoneyLocal(totalProfit),
                    setValueOptions
                  );
                  setValue(`${fieldPrefix}.unitProfit`, formatMoneyLocal(profit), setValueOptions);
                  setValue(`${fieldPrefix}.unitCost`, formatMoneyLocal(unitCost), setValueOptions);
                  setValue(
                    `${fieldPrefix}.subTotalCost`,
                    formatMoneyLocal(totalCost),
                    setValueOptions
                  );
                } else {
                  const unitCost = currencyToNumber(scopeEst.unitCost);
                  const markup = (profitPers / 100) * unitCost;
                  const unitPrice = unitCost + markup;
                  const total = qty * unitPrice;
                  const totalProfit = qty * markup;

                  setValue(
                    `${fieldPrefix}.subTotalProfit`,
                    formatMoneyLocal(totalProfit),
                    setValueOptions
                  );
                  setValue(`${fieldPrefix}.unitProfit`, formatMoneyLocal(markup), setValueOptions);
                  setValue(
                    `${fieldPrefix}.unitPrice`,
                    formatMoneyLocal(unitPrice),
                    setValueOptions
                  );
                  setValue(`${fieldPrefix}.subTotalCtr`, formatMoneyLocal(total), setValueOptions);
                }

                recalculateTotals(itemIndex);
              }}
            />
          ) : (
            <FormInput
              type='number'
              inputClassName={`form-control text-end `}
              className={formInputClass}
              control={control}
              key={`${fieldPrefix}.unitProfit`}
              name={`${fieldPrefix}.unitProfit`}
              placeholder='Markup ($)'
              disabled={isLocked || hasLineItems || isGroupItem}
              CustomInput={CurrencyInput}
              onChange={(e) => {
                const profit = currencyToNumber(e);
                const qty = currencyToNumber(scopeEst.qty);

                if (isTotalLocked) {
                  const unitPrice = currencyToNumber(scopeEst.unitPrice);
                  const unitCost = unitPrice - profit;
                  const profitPers = unitCost === 0 ? 0 : (profit / unitCost) * 100;
                  const totalCost = qty * unitCost;
                  const totalProfit = qty * profit;

                  setValue(
                    `${fieldPrefix}.subTotalProfit`,
                    formatMoneyLocal(totalProfit),
                    setValueOptions
                  );
                  setValue(
                    `${fieldPrefix}.subTotalProfitPers`,
                    profitPers.toFixed(1),
                    setValueOptions
                  );
                  setValue(`${fieldPrefix}.unitCost`, formatMoneyLocal(unitCost), setValueOptions);
                  setValue(
                    `${fieldPrefix}.subTotalCost`,
                    formatMoneyLocal(totalCost),
                    setValueOptions
                  );
                } else {
                  const unitCost = currencyToNumber(scopeEst.unitCost);
                  const unitPrice = unitCost + profit;
                  const total = qty * unitPrice;
                  const profitPers = unitCost === 0 ? 0 : (profit / unitCost) * 100;
                  const totalProfit = qty * profit;

                  setValue(`${fieldPrefix}.subTotalProfitPers`, profitPers, setValueOptions);
                  setValue(
                    `${fieldPrefix}.subTotalProfit`,
                    formatMoneyLocal(totalProfit),
                    setValueOptions
                  );
                  setValue(
                    `${fieldPrefix}.unitPrice`,
                    formatMoneyLocal(unitPrice),
                    setValueOptions
                  );
                  setValue(`${fieldPrefix}.subTotalCtr`, formatMoneyLocal(total), setValueOptions);
                }

                recalculateTotals(itemIndex);
              }}
            />
          ))}
        {detailedMode && visibleColumns.unitPrice && (
          <FormInput
            type='number'
            inputClassName={`form-control text-end `}
            className={formInputClass}
            control={control}
            key={`${fieldPrefix}.unitPrice`}
            name={`${fieldPrefix}.unitPrice`}
            placeholder='Unit Price'
            disabled={isLocked || hasLineItems || isTotalLocked || isGroupItem}
            CustomInput={CurrencyInput}
            onChange={(e) => {
              const unitPrice = currencyToNumber(e);
              const qty = currencyToNumber(scopeEst.qty);
              const unitCost = currencyToNumber(scopeEst.unitCost);

              const markup = unitPrice - unitCost;
              const markupPercent = unitCost === 0 ? 0 : (markup / unitCost) * 100;

              const total = qty * unitPrice;
              const totalProfit = qty * markup;

              setValue(`${fieldPrefix}.unitProfit`, formatMoneyLocal(markup), setValueOptions);
              setValue(
                `${fieldPrefix}.subTotalProfit`,
                formatMoneyLocal(totalProfit),
                setValueOptions
              );
              setValue(
                `${fieldPrefix}.subTotalProfitPers`,
                markupPercent.toFixed(1),
                setValueOptions
              );
              setValue(`${fieldPrefix}.subTotalCtr`, formatMoneyLocal(total), setValueOptions);

              recalculateTotals(itemIndex);
            }}
          />
        )}
        {(!detailedMode || visibleColumns.total) && (
          <FormInput
            type='number'
            inputClassName={`form-control text-end `}
            className={formInputClass}
            control={control}
            key={`${fieldPrefix}.subTotalCtr`}
            name={`${fieldPrefix}.subTotalCtr`}
            placeholder='Total'
            disabled={isLocked || isTotalLocked || hasLineItems || isGroupItem}
            CustomInput={CurrencyInput}
            onChange={(e) => {
              const total = currencyToNumber(e);
              const qty = currencyToNumber(scopeEst.qty);
              const unitCost = currencyToNumber(scopeEst.unitCost);

              const profit = total - qty * unitCost;
              const unitPrice = total / qty;
              const markupPercent = unitCost === 0 ? 0 : (profit / unitCost) * 100;
              const totalProfit = qty * profit;

              setValue(`${fieldPrefix}.unitPrice`, formatMoneyLocal(unitPrice), setValueOptions);
              setValue(`${fieldPrefix}.unitProfit`, formatMoneyLocal(profit), setValueOptions);
              setValue(
                `${fieldPrefix}.subTotalProfit`,
                formatMoneyLocal(totalProfit),
                setValueOptions
              );
              setValue(
                `${fieldPrefix}.subTotalProfitPers`,
                markupPercent.toFixed(1),
                setValueOptions
              );

              recalculateTotals(itemIndex);
            }}
          />
        )}
        {detailedMode &&
          visibleColumns.taxable &&
          (!isGroupItem ? (
            <FormInput
              type='checkbox'
              inputClassName={`form-check-input  ` + styles.checkbox}
              className={formInputClassExtraShort}
              control={control}
              key={`${fieldPrefix}.taxable`}
              name={`${fieldPrefix}.taxable`}
              disabled={isLocked}
            />
          ) : (
            <div className={formInputClassExtraShort}></div>
          ))}
        {detailedMode &&
          visibleColumns.billable &&
          (!isGroupItem ? (
            <FormInput
              type='checkbox'
              inputClassName={`form-check-input  ` + styles.checkbox}
              className={formInputClassExtraShort}
              control={control}
              key={`${fieldPrefix}.billable`}
              name={`${fieldPrefix}.billable`}
              disabled={isLocked}
            />
          ) : (
            <div className={formInputClassExtraShort}></div>
          ))}

        {!isLocked && (
          <div className={`px-1 ${styles.controlBtn} ms-auto me-2 ms-2`}>
            {!isLocked && detailedMode && isGroupItem ? (
              <>
              <div className='ms-4'>
                &nbsp;
                </div>
                <div>
                <a className={``} onClick={() => onAddLineItem()}>
                  <i
                    className={`fa-solid fa-plus-circle ${styles.addButton}`}
                    aria-hidden='true'
                    title='Add line item'
                  ></i>
                </a>
              </div>
              </>
            ) : detailedMode ? (
              <>
              <div className='ms-4'>
              &nbsp;
              </div>
              <div>
                <a onClick={() => onAddFirstLevelItem(itemIndex)}>
                  <i
                    className={`fa-solid fa-plus-circle ${styles.addButton}`}
                    aria-hidden='true'
                    title='Add line item'
                  ></i>
                </a>
              </div>
              </>
            ) : (
              <div className={`ms-0`}></div>
            )}
            <a onClick={() => onRemoveFirstLevelItem(itemIndex)}>
              <i
                className={`fa-solid fa-circle-xmark ${styles.deleteButton}`}
                aria-hidden='true'
                title='Remove'
              ></i>
            </a>
          </div>
        )}
      </div>
      {detailedMode && (
        <DragDropContext onDragEnd={onDragEndNested}>
          <Droppable droppableId={`scope-${id}`}>
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {estData[itemIndex].items?.map(renderLineItem)}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
      {detailedMode && estData[itemIndex].items?.length > 0 && <br />}
    </>
  );
};
